.imgSize {
  height: 100vh;
}

.cont {
  position: relative;
}

.contNav {
  position: absolute;
  width: 100%;
  height: 100%;
}

.contNav {
  z-index: 10;
}

.joinUs {
  margin-top: 3em;
}

.joinUs h1 {
  font-size: 5vw;
  font-weight: bolder;
}

.joinUs h2 {
  font-size: 3vw;
  font-weight: 600;
}

.joinUs h3 {
  font-size: 3vw;
  font-weight: 600;
}

.joinUs h4 {
  font-size: 2vw;
  font-weight: 600;
}

.formJoin {
  display: flex;
  /* justify-content:space-evenly; */
  flex-direction: column;
}

.formJoin input {
  border: 2px solid rgb(34, 114, 219);
  border-radius: 40px;
  height: 2.5em;
  width: 80vh;
}

.formJoin input:nth-child(5) {
  margin-top: 1.5em;
  border-color: green;
  cursor: pointer;
  background-color: #e0ecf0;
}

.formJoin h3 {
  font-size: 2.8vw;
  font-weight: bold;
}

.mobMascot {
  position: relative;
  z-index: 5;
}


.event {
  position: relative;
  padding: 20px 0;
  margin-top: 3em;
  background: #e0ecf0;
  overflow: visible;
  z-index: -1;
}

.event:before,
.event:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  transform-origin: left top;
  transform: skewY(-3deg);
}

.event:after {
  bottom: 0;
  transform-origin: left bottom;
  transform: skewY(3deg);
}

.event h1,
.partner h1 {
  color: #023a51;
  font-size: 4.5vw;
  font-weight: bolder;
}






.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
  pointer-events: auto;
}

.card-item {
  transition: 0.5s;
  max-width: 400px;
  margin: 1em;
}

.card-item img {
  width: 70%;
}

.card-item .card-details .card-meta {
  border-bottom: 1px solid #dee2e6;
}

/* .card-item .card-details .card-meta ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  gap: 10px;
  display: flex;
  justify-content: space-between;
}

.card-item .card-details .card-meta ul li a {
  color: #495057;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
}

.card-item .card-details .card-meta ul li a:hover {
  color: #2874f0;
} */

.card-item .card-details .title a {
  color: #212529;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5em;
  text-decoration: none;
  font-size: 20px;
}

.card-item .card-details .title {
  color: #2874f0;
}

.card-item .card-details .excerpt {
  font-size: 16px;
  color: #495057;
  /* margin-bottom: 30px; */
}

.card-details Button {
  text-decoration: none;
  color: #ffffff;
  background-color: #212529;
  padding: 10px 20px;
  border-radius: 5px;
}

.card-item:hover {
  border-color: #212529;
}






.partner {
  margin-top: 4em;
}

.partner .cards img {
  width: 6em;
  height: 5em;
  margin: 1.8em;
}






.social-container {
  padding: 25px 50px;
  text-align: center;
  padding: 20px 0;
  margin-top: 2em;
  background: #e0ecf0;
  margin-bottom: -3em;
}


.midSocial {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

}

a.social {
  margin: 1rem 0.5rem;
  transition: transform 250ms;
  display: inline-block;
  cursor: pointer;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: #E1306C;
}


.footer {
  background-color: #212121;
  color: #949494;
  /* height: auto; */
  margin-bottom: -2em;
}

.footer span {
  color: #ffffff;
}




.btni {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid red; /* Replace $red with an actual color value */
  border-radius: 0.6em;
  color: red; /* Replace $red with an actual color value */
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: auto;
  align-self: center;
  font-size: 2em;
  /* line-height: 1; */
  /* margin: 20px; */
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  /* font-weight: 400; */
  width: 28vw;
  /* height: 10vh; */
}

.btni:hover,
.btni:focus {
  color: #000000;
  outline: 0;
}

.third {
  border-color: #49a1eb; /* Replace $#49a1eb with an actual color value */
  color: #fff;
  box-shadow: 0 0 40px 40px #49a1eb inset, 0 0 0 0 #49a1eb; /* Replace $#49a1eb with an actual color value */
  transition: all 150ms ease-in-out;
}

.third:hover {
  box-shadow: 0 0 10px 0 #49a1eb inset, 0 0 10px 4px #49a1eb; /* Replace $#49a1eb with an actual color value */
}


@media only screen and (max-width: 420px) {
  .midSocial {
    flex-flow: row wrap;
  }
}


@media only screen and (max-width: 1000px) {
  .navImg {
    width: 3em;
    height: 3.5em;
  }

  .imgSize {
    height: auto;
  }

  .joinUs h1 {
    font-size: 5vh;
    font-weight: bolder;
  }

  .joinUs h2 {
    font-size: 2.8vh;
    font-weight: 600;
  }

  .joinUs h3 {
    font-size: 2vh;
    font-weight: 650;
  }

  .joinUs h4 {
    font-size: 2vh;
    font-weight: 700;
  }

  .mobMascot {
    flex-direction: column;
  }

  .mobMascot img {
    width: 30vh;
    padding-bottom: 3em;
    margin: auto;
  }

  .formJoin input {
    width: 90vw;
    margin: auto;
  }

  .formJoin input:nth-child(4) {
    margin-bottom: 2em;
  }

  .event h1,
  .partner h1 {
    font-size: 4.5vh;
  }

  .partner .cards img {
    width: 4.5em;
    margin: .6em;
  }

  .formJoin h3 {
    font-size: 3vh;
  }

  .btni{
    font-size: 1.5em;
    width: 28vh;
  }
}











#button {
  display: inline-block;
  background-color: #49a1eb ;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: background-color .3s,
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

#button::after {
  content: "^";
  font-family: FontAwesome;
  font-weight: 1000;
  font-size: 1.5em;
  line-height: 45px;
  color: #fff;
  margin: auto;
}

#button:hover {
  cursor: pointer;
  background-color: #333;
}

#button:active {
  background-color: #555;
}

#button.show {
  opacity: 1;
  visibility: visible;
}


